@import "../../global.scss";
.topbar{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top:0;
    z-index: 9;
    backdrop-filter: blur(5px);
    box-sizing: border-box;
    padding: 18px 32px;
    &.bg_dark{
        background: #191C1F;
    }
    
    &.bg_light{
        background: #fff;
        border-bottom: 1px solid #E2E2E2
    }
    &.expand{
        padding: 18px 80px 50px 80px;
        @include mobile_potrait {
            padding: 10px 16px 54px 16px !important;
        }

        .container .wrapper .row .search {
            margin-left: 0 !important;
        }
    }
    @include mobile_potrait{
        padding: 10px 16px;
    }
    .content{
        width: 100%;
        max-width: 1440px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        box-sizing: border-box;
        
        @include mobile_potrait{
            padding: 0px 0px;
        }
        .row{
            display: flex;
            align-items: center;
            grid-area: auto;
            gap : 24px;
        }
    }
    .logo{
        display: flex;
        align-items: center;
        justify-content: center;
        @include mobile_potrait{
        }
        
        a{
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            text-decoration: none;
           
            img{
                height: 39px;
                @include mobile_potrait{
                    height: 32px;
                }
                
            }
            
        }
    }
    .balance_div{
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 8px;
        margin-left: auto;
        @include mobile_potrait{
            width: 100%;
            justify-content: space-between;
            position: absolute;
            bottom: 8px;
        }

        .balance{
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-direction: row;
            position: relative;
            @include mobile_potrait{
            }
            
            p{
                color: #626262;
                font-size: MIN(1vw, 14px);
                text-transform: uppercase;
                text-shadow: 3px 3px 5px #000;
                font-weight: normal;
                z-index: 1;
                margin-right: 1rem;
                display: flex;
                align-items: center;
                letter-spacing: -1px;
                span{
                    color: #ff0099;
                    font-weight: 600;
                }
                @include mobile_potrait{
                    font-size: 0.6rem;
                    margin-right: 0.5rem;
                }
                img{
                    height: 24px;
                    margin-left: 8px;
                    @include mobile_potrait{
                        height: 18px;
                        margin-left: 5px;
                    }
                }
                
            }
            .mob_text{
                @include mobile_potrait{
                } 
            }
            
        }
        

    }
    .connectBtn{
        @include mobile_potrait{
            padding : 0.6rem 0.5rem;
        }
        
        p{
            @include mobile_potrait{
                font-size: 10px;
            }
        }
    }
    .icon_link{
        img{
            transition: all 0.3s ease;
        }
        &:hover{
            img{
                filter: drop-shadow(3px 3px 8px #FF4835);
            }
        }
    }
    .menu_list{
        display: none;
        align-items: center;
        justify-content: center;
        grid-area: auto;
        gap : 16px;

        @media only screen and (min-width: 1200px) {
            display: flex;
        }

        @media only screen and (max-width: 1200px) {
            display: flex;
            position: absolute;
            background: #000;
            height: 100vh;
            width: 100vw;
            top: 0;
            right: 0;
            text-align: left;
            z-index: 2;
            transition: all 0.3s ease;
            right: -100vw;
            justify-content: flex-start;
            box-sizing: border-box;
            padding-top: 80px;
            &.menu-open {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: flex-start;
                top: -0px;
                right: 0;
                .menu-item {
                    padding-left: 00px;
                    .label{
                        font-size: 22px;
                        
                    }
                }
                .closeContainer {
                    width: 100%;                                
                    display: flex;
                    flex-wrap: nowrap;
                    justify-content: flex-end;
                    padding-right: 20px;
                }
            }                  
        }
        .closeContainer{
            svg{
                fill: #eee;
            }
        }
        .my_theme_slelct{
            display: none;
            @include mobile_potrait{
                display: flex;
            }
        }
        
    }
    .search_btn{
        display: none;
        @media only screen and (max-width: 870px) {
            display: flex;
            border: none;
            background-color: transparent;
            padding: 0;
            cursor: pointer;
            font-size: 20px;
        }
    }
    .search_div{
        margin-left: 41px;
        position: relative;
        display: flex;
        align-items: center;
        @media only screen and (max-width: 870px) {
            display: none;
            position :absolute;
            bottom : 5px;
            margin: auto;
            left : 50%;
            transform: translateX(-50%);
        }
        &.show-search{
            display: flex !important;
        }
    }
    .search {
        position: relative;
        display: flex;
        align-items: center;
        button{
            position: absolute;
            border : none;
            background: #FFFFFF14;
            font-size: 13px;
            right : 8px;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 24px;
            height: 24px;
            border-radius: 8px;
            &.bg_light{
                background: rgba(0, 0, 0, 0.05);
            }
        }
        input {
            font-size: 14px;
            line-height: 20px;
            padding: 10px 50px 10px 16px;
            border: none;
            border-radius: 8px;
            background-color: #FFFFFF0A;
            min-width: 300px;
            &.bg_light{
                background: rgba(0, 0, 0, 0.05);
            }
            &:focus{
                outline: none;
            }
            @include mobile_potrait{
                min-width: 250px;
            }
        }
    }
    .my_theme_slelct{
        @include mobile_potrait{
            display: none;
        }
    }
    .btn_div{
        display: flex;
        align-items: center;
        grid-area: auto;
        gap: 24px;
        @include mobile_potrait{
            gap: 8px;
            margin-right: 28px;
        }
    }
    .hamburger{
        display: none;
        width: 2.5rem;
        height: 3rem;
        position: absolute;
        right : 2rem;
        top : 1rem;
        flex-direction: column;
        justify-content: space-around;
        cursor: pointer;
        z-index: 6;
        overflow: hidden;
        transform: scale(0.9);
        @include mobile_potrait{
            width: 2rem;
            height: 2rem;
            right : 10px;
            top : 10px;
            flex-direction: column;
            justify-content: space-around;
            cursor: pointer;
            z-index: 6;
            overflow: hidden;
            transform: scale(0.9);
            display: flex;
        }
        @include mobile_landscape{
            width: 2.5rem;
            height: 2.2rem;
            right : 1rem;
            top : 1.3rem;
            flex-direction: column;
            justify-content: space-around;
            cursor: pointer;
            z-index: 6;
            overflow: hidden;
            transform: scale(0.9);
        }
        span{
            width: 100%;
            height: 3px;
            border-radius: 3px;
            transform-origin: left;
            transition: all 1s ease;
            &.bg_dark{
                background: #fff;
            }
            
            &.bg_light{
                background: #000;
            }
        }
        &.active{
            z-index: 1;
            span{
                &:first-child{
                    width: 2.2rem;
                    transform: rotate(40deg);
                    @include mobile_potrait{
                        transform: rotate(35deg);
                    }
                    @include mobile_landscape{
                        transform: rotate(30deg);
                    }
                }
                &:nth-child(2){
                    opacity: 0;
                }
                &:last-child{
                    width: 2.2rem;
                    transform: rotate(-40deg);
                    @include mobile_potrait{
                        transform: rotate(-35deg);
                    }
                    @include mobile_landscape{
                        transform: rotate(-30deg);
                    }
                }

            }
        }
    }
}