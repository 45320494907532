@import "../../global.scss";
.accountModal {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.61);
  z-index: 25;
  display: none;
  opacity: 1;
  &.active {
    display: flex;
  }
  .modelContent {
    z-index: 5;
    width: 25rem;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    position: relative;
    opacity: 1;
    border-radius: 10px;
    padding: 1rem;
    border-radius: 10px;
    background-size: 200%;
    background: #000000de;
    border: 1px #333 solid;
    @include mobile_potrait {
      width: 20rem;
    }

    
    .connectWalletHeader {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      z-index: 1;
      margin-bottom: 1rem;

      @include mobile_potrait {
        border-top-left-radius: 1rem;
        border-top-right-radius: 1rem;
      }

      .connectWalletTitle {
        font-size: 20px;
        font-weight: normal;
        padding-left: 20px;
        color: #fff;
        @include mobile_potrait {
          font-size: 1.5rem;
        }
      }
      .connectModalCloseButton {
        font-size: 2rem;
        font-weight: 600;
        border: none;
        background-color: #00000000;
        border-radius: 16px;
        cursor: pointer;
        color: #fff;
        margin-right: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: all 0.3s ease;
        @include mobile_potrait {
          font-size: 1.5rem;
        }
        &:hover {
          animation: closeAni1 0.5s ease 0s infinite;
          @keyframes closeAni1 {
            0% {
              opacity: 0.3;
            }
            50% {
              opacity: 1;
            }
            100% {
              opacity: 0.3;
            }
          }
        }
      }
    }

    .modalWraper {
      width: 90%;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-direction: column;
      z-index: 1;
      span {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        margin-bottom: 1rem;
        .avatar {
          width: 3rem;
        }
        input {
          border: none;
          background-color: #ffffff00;
          margin-left: 1rem;
          font-size: 14px;
          color: #fff;
          width: 250px;
          letter-spacing: -1px;
          &:focus {
            outline: none;
          }
          @include mobile_potrait {
            width: 6rem;
            margin-left: 0.5rem;
            font-size: 0.8rem;
          }
          
        }
        .coppyAddressButton {
          cursor: pointer;
          border: none;
          background-color: #ffffff00;
          color: #fff;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 1rem;
          transition: all 0.3s ease;
          &:hover {
            color: #aaa;
          }
        }
      }
      .modalBtns {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-around;
        flex-direction: row;
        margin: 0 !important;
        
        .disconnect {
          p{
            font-size: 12px;
          }
        }
      }
    }
  }
}
