@import "../../global.scss";

.chatSection {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    position: relative;
    z-index: 1;
    box-sizing: border-box;
    padding: 0 32px;
    padding-top: 82px;
    height: 100vh;

    @include mobile_potrait {
        padding: 0;
        padding-top: 60px;
        height: 100%;
    }

    .content {
        width: 100%;
        height: 100%;
        z-index: 2;
        display: flex;
        max-width: 1440px;

        @include mobile_potrait {}

        h1 {
            font-size: 42px;
            font-weight: normal;
            color: #FF0099;
            text-align: center;
            text-shadow: 0 1px #808d93, -1px 0 #cdd2d5, -1px 2px #808d93, -2px 1px #cdd2d5, -2px 3px #808d93, -3px 2px #cdd2d5, -3px 4px #808d93, -4px 3px #cdd2d5, -4px 5px #808d93, -5px 4px #cdd2d5, -5px 6px #808d93, -6px 5px #cdd2d5, -6px 7px #808d93, -7px 6px #cdd2d5, -7px 8px #808d93, -8px 7px #cdd2d5;
            width: 100%;
            text-align: center;
            margin-bottom: 3rem;
            letter-spacing: -1px;

            @include mobile_potrait {
                font-size: 24px;
                width: 100%;
                line-height: 36px;
            }
        }

        .wrapper {
            width: 100%;
            display: flex;
            justify-content: space-between;
            flex-direction: row;
            flex-wrap: wrap;
            height: 100%;

            @include mobile_potrait {
                justify-content: flex-start;
                align-items: center;
                flex-direction: column;
            }

            .left {
                width: 400px;
                display: flex;
                flex-direction: column;
                box-sizing: border-box;
                box-sizing: border-box;
                padding: 24px;
                height: 100%;
                transition: all 0.3s ease;

                &.active {
                    right: 0;
                }

                &.bg_dark {
                    background: #141420;
                }

                &.bg_light {
                    background-color: #fff;
                }

                @include mobile_potrait {
                    width: 100%;
                    padding: 16px;

                    position: absolute;
                    right: 100%;
                    z-index: 2;
                }

                .left_content {
                    display: flex;
                    flex-direction: column;
                    width: 100%;
                    grid-area: auto;
                    gap: 24px;
                    height: 100%;
                    position: relative;

                    @include mobile_potrait {
                        gap: 16px;
                        box-sizing: border-box;
                        padding-top: 24px;
                    }

                    .close_btn {
                        display: none;
                        border: none;
                        padding: 0;
                        font-size: 24px;
                        width: 24px;
                        height: 24px;
                        align-items: center;
                        justify-content: center;
                        cursor: pointer;
                        background-color: #ffffff00;
                        position: absolute;
                        left: 0;
                        top: 0;

                        @include mobile_potrait {
                            display: flex;
                        }
                    }
                }

                .chatItem_list {
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    grid-area: auto;
                    gap: 24px;
                    overflow-y: scroll;
                    height: calc(100% - 165px);

                    @include mobile_potrait {
                        gap: 16px;
                        height: calc(100% - 265px);
                    }

                    &::-webkit-scrollbar {
                        display: none;
                    }

                    .header_label {
                        width: 100%;

                        h3 {
                            font-size: 16px;
                            font-weight: 400;
                            color: #5A717C;
                            display: flex;
                            align-items: center;

                            img {
                                margin-right: 8px;
                            }

                            @include mobile_potrait {
                                font-size: 12px;
                            }
                        }
                    }
                }
            }

            .right {
                width: calc(100% - 402px);
                height: 100%;

                @include mobile_potrait {
                    width: 100%;
                }

                &.border_dark {
                    border-right: 2px solid #FFFFFF0D;
                }

                &.border_light {
                    border-right: 2px solid #fff;
                }

                &.bg_dark {
                }

                &.bg_light {
                    background-color: #fff;
                }

                .right_content {
                    width: 100%;
                    box-sizing: border-box;

                    display: flex;
                    flex-direction: column;
                    height: 100%;

                    .tab_list {
                        width: 100%;
                        box-sizing: border-box;
                        display: flex;
                        align-items: center;
                        padding: 20px 24px;
                        position: relative;
                        grid-area: auto;
                        gap: 16px;

                        @include mobile_potrait {
                            padding: 10px 16px;
                            padding-left: 56px;

                        }

                        &.bg_dark {
                            background: #FFFFFF0D;
                        }

                        &.bg_light {
                            background-color: #fff;
                        }
                    }

                    .tab_content {
                        display: flex;
                        flex-direction: column;
                        width: 100%;
                        height: calc(100% - 67px);
                        padding: 24px;
                        box-sizing: border-box;
                        grid-area: auto;
                        overflow: scroll;

                        &::-webkit-scrollbar {
                            display: none;
                        }

                        @include mobile_potrait {
                            padding: 0px 16px;
                            gap: 16px;
                        }

                        .sub_title {
                            font-size: 24px;
                            font-weight: 600;
                            margin-bottom: 16px;

                            @include mobile_potrait {
                                font-size: 20px;
                            }
                        }

                        .sub_text {
                            font-size: 14px;
                            margin-bottom: 16px;

                            @include mobile_potrait {
                                font-size: 12px;
                            }
                        }

                        .send_invide_div {
                            box-sizing: border-box;
                            border-radius: 24px;
                            display: flex;
                            align-items: center;
                            padding: 16px;
                            justify-content: space-between;

                            @include mobile_potrait {
                                padding: 10px;
                                border-radius: 16px;
                            }

                            &.dark {
                                background: linear-gradient(0deg, #34343A, #34343A), linear-gradient(0deg, #47475C, #47475C);
                                border: 1px solid #47475C;
                            }

                            &.light {
                                border: 1px solid #DFE1E2;
                            }

                            input {
                                width: 100%;
                                width: calc(100% - 180px);
                                border: none;
                                background-color: #ffffff00;

                                &:focus {
                                    outline: none;
                                }
                            }

                            button {
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                border: none;
                                padding: 8px 16px;
                                border-radius: 10px;
                                cursor: pointer;
                                transition: all 0.3s ease;

                                &:hover {
                                    filter: brightness(0.5);
                                }


                                &.dark {
                                    background-color: #ffffff33;
                                    color: #fff;
                                }

                                &.light {
                                    background-color: #00000033;
                                    color: #000;
                                }

                            }
                        }
                    }

                    .right_header {
                        width: 100%;
                        box-sizing: border-box;
                        display: flex;

                        align-items: center;
                        padding: 20px 24px;
                        position: relative;
                        grid-area: auto;
                        gap: 16px;

                        @include mobile_potrait {
                            padding: 10px 16px;
                            padding-left: 56px;
                        }

                        &.bg_dark {
                            background: #FFFFFF0D;
                        }

                        &.bg_light {
                            background-color: #fff;
                        }

                        .user_info {
                            width: calc(100% - 76px);
                            display: flex;
                            flex-direction: column;

                            @include mobile_potrait {
                                width: calc(60% - 76px);
                            }

                            h4 {
                                width: 100%;
                                white-space: nowrap;
                                font-size: 20px;
                                text-overflow: ellipsis;
                                overflow: hidden;
                                white-space: nowrap;
                                font-weight: 500;
                            }

                            p {
                                width: 100%;
                                white-space: nowrap;
                                font-size: 14px;
                                text-overflow: ellipsis;
                                overflow: hidden;
                                white-space: nowrap;
                                cursor: pointer;
                            }
                        }

                        .user_search {
                            position: absolute;
                            right: 24px;
                            display: flex;
                            align-items: center;

                            .search {
                                width: 100%;
                                box-sizing: border-box;
                                padding: 10px 16px;
                                border-radius: 16px;
                                display: flex;
                                align-items: center;
                                padding-right: 50px;
                                position: relative;

                                &.bg_dark {
                                    background-color: #34343A;
                                }

                                &.bg_light {
                                    background-color: #F0F1F4;
                                }

                                input {
                                    width: 100%;
                                    border: none;
                                    background-color: #ffffff00;

                                    &:focus {
                                        outline: none;
                                    }
                                }

                                @include mobile_potrait {
                                    display: none;
                                }
                            }

                            .icon_btn {
                                border: none;
                                padding: 0;
                                cursor: pointer;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                font-size: 30px;
                                margin-left: 16px;

                                @include mobile_potrait {
                                    display: none;
                                }
                            }
                        }

                        .show_btn {
                            display: none;
                            border: none;
                            padding: 0;
                            font-size: 22px;
                            width: 24px;
                            height: 24px;
                            align-items: center;
                            justify-content: center;
                            cursor: pointer;
                            background-color: #ffffff00;
                            position: absolute;
                            left: 16px;

                            @include mobile_potrait {
                                display: flex;
                            }
                        }

                        .show_users_div {
                            margin-left: 16px;

                            button {
                                display: flex;
                                align-items: center;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                border: none;
                                padding: 8px 20px;
                                border-radius: 10px;
                                cursor: pointer;
                                transition: all 0.3s ease;

                                @include mobile_potrait {
                                    font-size: 12px;
                                    padding: 6px 16px;
                                }

                                &:hover {
                                    filter: brightness(0.5);
                                }

                                &.dark {
                                    background-color: #ffffff33;
                                    color: #fff;
                                }

                                &.light {
                                    background-color: #00000033;
                                    color: #000;
                                }

                                .fas {
                                    margin-right: 8px;
                                }
                            }
                        }

                        .tab_btns {
                            display: flex;
                            align-items: center;
                            grid-area: auto;
                            gap: 16px;

                            @include mobile_potrait {
                                gap: 0px;
                            }

                            button {
                                display: flex;
                                align-items: center;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                border: none;
                                padding: 8px 20px;
                                border-radius: 10px;
                                cursor: pointer;
                                transition: all 0.3s ease;
                                background-color: #ffffff00;
                                font-size: 14px;

                                @include mobile_potrait {
                                    padding: 6px 10px;
                                    font-size: 12px;
                                }

                                &:hover {
                                    filter: brightness(0.5);
                                }

                                &.dark {
                                    // background-color: #ffffff33;
                                    color: #fff;
                                }

                                &.light {
                                    // background-color: #00000033;
                                    color: #000;
                                }

                                .fas {
                                    margin-right: 8px;
                                }

                                &.addBtn {
                                    background: linear-gradient(92.73deg, #FF00FF 0.61%, #FE511B 99.34%);
                                    color: #fff !important;
                                }

                                &:hover {
                                    &.dark {
                                        background-color: #ffffff33;
                                        color: #fff;
                                    }

                                    &.light {
                                        background-color: #00000033;
                                        color: #000;
                                    }
                                }

                                &.activeBtn {
                                    &.dark {
                                        background-color: #ffffff33;
                                        color: #fff;
                                    }

                                    &.light {
                                        background-color: #00000033;
                                        color: #000;
                                    }

                                    &.addBtn {
                                        // background: linear-gradient(92.73deg, #FF00FF33 0.61%, #FE511B33 99.34%);
                                        background: linear-gradient(to right, #FF00FF, #FE511B);
                                        background-clip: text;
                                        -webkit-text-fill-color: transparent;
                                    }
                                }
                            }
                        }

                    }

                    .messages_div {
                        display: flex;
                        flex-direction: column;
                        align-items: flex-end;
                        width: 100%;
                        height: calc(100% - 62px);
                        padding: 24px;
                        box-sizing: border-box;
                        grid-area: auto;
                        gap: 24px;
                        overflow: scroll;

                        &::-webkit-scrollbar {
                            display: none;
                        }

                        @include mobile_potrait {
                            padding: 0px 16px;
                            gap: 16px;
                        }
                    }

                    .input_div {
                        width: 100%;
                        display: flex;
                        align-items: center;
                        grid-area: auto;
                        gap: 16px;
                        padding: 24px;
                        box-sizing: border-box;
                        padding-bottom: 0;

                        @include mobile_potrait {
                            padding: 10px 16px;
                        }

                        .input {
                            width: calc(100% - 58px);
                            box-sizing: border-box;
                            border-radius: 24px;
                            display: flex;
                            align-items: center;

                            padding: 16px;

                            justify-content: space-between;

                            @include mobile_potrait {
                                padding: 10px;
                                border-radius: 16px;
                            }

                            &.dark {
                                background: linear-gradient(0deg, #34343A, #34343A), linear-gradient(0deg, #47475C, #47475C);
                                border: 1px solid #47475C;
                            }

                            &.light {
                                border: 1px solid #DFE1E2;
                            }

                            .search {
                                width: calc(100% - 130px);

                                input {
                                    width: 100%;
                                    border: none;
                                    background-color: #ffffff00;

                                    &:focus {
                                        outline: none;
                                    }
                                }
                            }

                            .btns_div {
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                grid-area: auto;
                                gap: 16px;

                                @include mobile_potrait {
                                    gap: 8px;
                                }
                            }

                        }
                    }

                }
            }

            .line_light {
                width: 100%;
                border-bottom: 1px #E3E6E8 solid;
            }

            .avatar_list {
                width: 100%;

                .slick-slide {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }

                .slick-next {
                    // right: max(calc((100% - 1310px) / 2), 20px);
                    right: -20px;
                    z-index: 1;
                    width: 32px;
                    height: 32px;
                    background: #00000000;
                    border-radius: 40px;
                    transition: all 0.3s ease;
                    cursor: pointer;

                    &:hover {
                        filter: brightness(1.5);
                    }

                    @include mobile_potrait {}

                    &::before {
                        content: "";
                        position: absolute;
                        width: 10px;
                        height: 2px;
                        transform: rotate(45deg) translate(-2.5px, -2.5px);
                        background: #5E6D78;
                        right: 12px;
                    }

                    &::after {
                        content: "";
                        position: absolute;
                        width: 10px;
                        height: 2px;
                        transform: rotate(-45deg) translate(-2.5px, 2.5px);
                        background: #5E6D78;
                        right: 12px;
                    }
                }

                .slick-prev {
                    // left: max(calc((100% - 1310px) / 2), 20px);
                    left: -20px;
                    z-index: 1;
                    width: 32px;
                    height: 32px;
                    background: #00000000;
                    border-radius: 40px;
                    transition: all 0.3s ease;
                    cursor: pointer;

                    &:hover {
                        filter: brightness(1.5);
                    }

                    &::before {
                        content: "";
                        position: absolute;
                        width: 10px;
                        height: 2px;
                        transform: rotate(45deg) translate(2.5px, 2.5px);
                        background: #5E6D78;
                        left: 12px;
                    }

                    &::after {
                        content: "";
                        position: absolute;
                        width: 10px;
                        height: 2px;
                        transform: rotate(-45deg) translate(2.5px, -2.5px);
                        background: #5E6D78;
                        left: 12px;
                    }

                    @include mobile_potrait {}
                }
            }

            .header {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: space-between;

                h2 {
                    font-size: 32px;
                    font-weight: 500;
                    display: flex;
                    align-items: center;
                    line-height: 1;

                    @include mobile_potrait {
                        font-size: 24px;
                    }

                    span {
                        font-size: 18px;
                        margin-bottom: 8px;
                        margin-left: 8px;
                    }
                }
            }

            .icon_btn {
                display: flex;
                align-items: center;
                justify-content: center;
                border: none;
                padding: 0;
                background-color: #ffffff00;
                cursor: pointer;

                .dark {
                    filter: brightness(0.7);

                    &:hover {
                        filter: brightness(0.5);
                    }
                }

                .light {
                    &:hover {
                        filter: brightness(0.5);
                    }
                }

                img {
                    transition: all 0.3s ease;
                }

            }

            .search_div {
                width: 100%;
                box-sizing: border-box;
                padding: 16px;
                border-radius: 16px;
                display: flex;
                align-items: center;
                padding-right: 50px;

                @include mobile_potrait {
                    padding: 10px;
                    border-radius: 10px;
                }

                position: relative;

                &.bg_dark {
                    background-color: #34343A;
                }

                &.bg_light {
                    background-color: #F0F1F4;
                }

                button {
                    position: absolute;
                    right: 15px;
                }

                input {
                    border: none;
                    background-color: #ffffff00;

                    &:focus {
                        outline: none;
                    }
                }
            }

        }

    }

    .chatItem {
        width: 100%;
        display: flex;
        cursor: pointer;

        &.border_light {
            padding-bottom: 16px;
            border-bottom: 1px #E3E6E8 solid;

        }

        .item_content {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            grid-area: auto;
            gap: 10px;
        }

        .message_detail {
            width: calc(100% - 202px);
            display: flex;
            flex-direction: column;

            @include mobile_potrait {
                width: calc(100% - 180px);
            }

            h4 {
                width: 100%;
                white-space: nowrap;
                font-size: 16px;
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
                font-weight: 500;

                @include mobile_potrait {
                    font-size: 18px;
                }
            }

            p {
                width: 100%;
                white-space: nowrap;
                font-size: 14px;
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;

                @include mobile_potrait {
                    font-size: 10px;
                }
            }
        }

        .stat_div {
            display: flex;
            align-items: flex-end;
            justify-content: center;
            flex-direction: column;

            .unread_div {
                width: 22px;
                height: 22px;
                border-radius: 100px;
                display: flex;
                align-items: center;
                justify-content: center;
                color: #fff;
                font-size: 14px;
                background: linear-gradient(180deg, #FF00E6 0%, #FF4835 100%);
                margin-bottom: 8px;
            }

            .time_div {
                white-space: nowrap;
                font-size: 14px;

                @include mobile_potrait {
                    font-size: 10px;
                }
            }
        }


    }

    .messageItem {
        width: 100%;
        display: flex;

        .item_content {
            width: 100%;
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            grid-area: auto;
            gap: 10px;

            &.row_reverse {
                flex-direction: row-reverse;

                .message_header {
                    justify-content: flex-end;

                    h4 {
                        text-align: end;
                        width: fit-content;
                        margin-right: 16px;
                    }
                }

                .message_list {
                    align-items: flex-end;
                }

                .message_detail {
                    margin-right: 8px;
                    margin-left: auto;
                }

                .message_item {
                    &.first {
                        border-radius: 16px 0px 16px 16px;

                        @include mobile_potrait {
                            padding: 10px;
                            border-radius: 10px 0px 10px 10px;
                        }
                    }

                    &.bg_dark {
                        background-color: #34343A;
                    }

                    &.bg_light {
                        background-color: #fff;
                    }

                }
            }
        }

        .message_detail {
            width: calc(100% - 72px);
            display: flex;
            flex-direction: column;

            .message_header {
                width: 100%;
                display: flex;
                align-items: center;
                margin-bottom: 8px;

                .time_div {
                    white-space: nowrap;
                    font-size: 14px;

                    @include mobile_potrait {
                        font-size: 10px;
                    }
                }
            }

            .message_list {
                display: flex;
                flex-direction: column;
                grid-area: auto;
                gap: 8px;
            }

            .message_item {
                padding: 16px;
                border-radius: 16px 16px 16px 16px;
                max-width: 50%;
                width: fit-content;

                @include mobile_potrait {
                    padding: 10px;
                    border-radius: 10px 10px 10px 10px;
                    max-width: 70%;
                }

                &.first {
                    border-radius: 0 16px 16px 16px;

                    @include mobile_potrait {
                        padding: 10px;
                        border-radius: 0px 10px 10px 10px;
                    }
                }

                &.bg_dark {
                    background-color: #34343A;
                }

                &.bg_light {
                    background-color: #fff;
                }

                img {
                    width: 100%;
                }

            }

            h4 {
                width: 50%;
                white-space: nowrap;
                font-size: 20px;
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
                font-weight: 500;

                @include mobile_potrait {
                    font-size: 18px;
                }
            }

            p {
                width: fit-content;
                font-size: 14px;
                color: #7B888E;

                @include mobile_potrait {
                    font-size: 10px;
                }
            }
        }
    }

    .userItem {
        width: 100%;
        display: flex;
        cursor: pointer;
        padding: 16px;
        box-sizing: border-box;
        position: relative;

        &.border_dark {
            padding-bottom: 16px;
            border-bottom: 1px #ffffff0d solid;
        }

        &.border_light {
            padding-bottom: 16px;
            border-bottom: 1px #E3E6E8 solid;
        }

        &:hover {
            &.border_dark {
                background-color: #ffffff05;
                border-bottom: 1px #ffffff05 solid;
            }

            &.border_light {
                background-color: #E3E6E8;
                border-bottom: 1px #E3E6E8 solid;
            }
        }

        .item_content {
            width: 100%;
            display: flex;
            align-items: center;
            grid-area: auto;
            gap: 10px;
        }

        .message_detail {
            width: calc(100% - 152px);
            display: flex;
            flex-direction: column;

            @include mobile_potrait {
                width: calc(100% - 180px);
            }

            h4 {
                width: 100%;
                white-space: nowrap;
                font-size: 20px;
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
                font-weight: 500;

                @include mobile_potrait {
                    font-size: 18px;
                }
            }

            p {
                width: 100%;
                white-space: nowrap;
                font-size: 14px;
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;

                @include mobile_potrait {
                    font-size: 10px;
                }
            }
        }

        .btns_div {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            grid-area: auto;
            gap: 16px;
            margin-right: 0;
            margin-left: auto;

            button {
                width: 26px;
                height: 26px;
                display: flex;
                align-items: center;
                justify-content: center;
                border: none;
                padding: 0;
                border-radius: 50%;
                cursor: pointer;
                transition: all 0.3s ease;

                &:hover {
                    filter: brightness(0.5);
                }


                &.dark {
                    background-color: #ffffff33;
                    color: #fff;
                }

                &.light {
                    background-color: #00000033;
                    color: #000;
                }

                &.red {
                    color: #FE511B !important;
                }
            }
        }
    }

    .loadingItem {
        width: 100%;
        display: flex;
        animation: ladingTempAni 1s ease 0s infinite;
        transition: all 0.3s ease;

        @keyframes ladingTempAni {
            0% {
                filter: brightness(1);
            }

            50% {
                filter: brightness(0.8);
            }

            100% {
                filter: brightness(1);
            }
        }

        .item_content {
            width: 100%;
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            grid-area: auto;
            gap: 10px;

            &.row_reverse {
                flex-direction: row-reverse;

                .message_header {
                    justify-content: flex-end;

                    h4 {
                        text-align: end;
                        width: fit-content;
                        margin-right: 16px;
                    }
                }

                .message_list {
                    align-items: flex-end;
                }

                .message_detail {
                    margin-right: 8px;
                    margin-left: auto;
                }

                .message_item {
                    &.first {
                        border-radius: 16px 0px 16px 16px;

                        @include mobile_potrait {
                            padding: 10px;
                            border-radius: 10px 0px 10px 10px;
                        }
                    }

                    &.bg_dark {
                        background-color: #34343A;
                    }

                    &.bg_light {
                        background-color: #eee;
                    }

                }
            }
        }

        .avatar {
            width: 62px;
            height: 62px;
            border-radius: 50%;

            &.bg_dark {
                background-color: #34343A;
            }

            &.bg_light {
                background-color: #eee;
            }
        }

        .message_detail {
            width: calc(100% - 72px);
            display: flex;
            flex-direction: column;

            .message_header {
                width: 100%;
                display: flex;
                align-items: center;
                margin-bottom: 8px;

                .time_div {
                    white-space: nowrap;
                    height: 14px;
                    width: 10%;
                    border-radius: 8px;

                    &.bg_dark {
                        background-color: #34343A;
                    }

                    &.bg_light {
                        background-color: #eee;
                    }

                    @include mobile_potrait {
                        font-size: 10px;
                    }
                }
            }

            .message_list {
                display: flex;
                flex-direction: column;
                grid-area: auto;
                gap: 8px;
            }

            .message_item {
                padding: 16px;
                border-radius: 16px 16px 16px 16px;
                max-width: 50%;
                width: fit-content;

                @include mobile_potrait {
                    padding: 10px;
                    border-radius: 10px 10px 10px 10px;
                    max-width: 70%;
                }

                &.first {
                    border-radius: 0 16px 16px 16px;

                    @include mobile_potrait {
                        padding: 10px;
                        border-radius: 0px 10px 10px 10px;
                    }
                }

                &.bg_dark {
                    background-color: #34343A;
                }

                &.bg_light {
                    background-color: #eee;
                }

                img {
                    width: 100%;
                }

            }

            h4 {
                width: 30%;
                margin-right: 20%;
                white-space: nowrap;
                height: 20px;
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
                font-weight: 500;
                border-radius: 8px;

                @include mobile_potrait {
                    font-size: 18px;
                }

                &.bg_dark {
                    background-color: #34343A;
                }

                &.bg_light {
                    background-color: #eee;
                }
            }

            p {
                width: fit-content;
                height: 14px;
                color: #7B888E;
                width: 200px;

                @include mobile_potrait {
                    font-size: 10px;
                }

                &.bg_dark {
                    background-color: #34343A;
                }

                &.bg_light {
                    background-color: #eee;
                }
            }

            .img {
                width: 250px;
                height: 300px;
            }
        }
    }
}