@import "../../global.scss";
.sidebar{
    height: 110vh;
    position: fixed;
    width: 100vw;
    right:-100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    transition: all 1s ease;
    backdrop-filter: blur(5px);
    z-index: 15;
    position: fixed;
    top: 60px;
    
    
    &.bg_dark{
        background: #191C1F;
    }
    &.bg_light{
        background: #fff;
        border-bottom: 1px solid #E2E2E2
    }

    @include mobile_potrait{
        width: 100vw;
        right:-100vw;
    }
    
    &.active{
        right: 0;
    }
    .menu_list{
        display: flex;
        flex-direction: column;
        grid-area: auto;
        gap: 24px;
        box-sizing: border-box;
        padding-top: 36px;
    }
} 