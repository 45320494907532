@import "../../../global.scss";
.menu-item{
    font-weight: 600;
    text-decoration:none;
    cursor: pointer;
    text-overflow: ellipsis;
    white-space: nowrap;
    
    &:hover {
        .drop_down{
            display: flex;
        }
        .label{
            background: linear-gradient(to right,#FF00FF, #FE511B);
            background-clip: text;
            -webkit-text-fill-color: transparent;
            .nav_icon{
                filter: drop-shadow(3px 3px 3px #FE511B);
            }
        }
    }
    @include mobile_potrait{
        font-size: 16px;
        
    }
    .label{
        transition: all 0.3s ease;
        font-size: min(1vw, 16px);
        @include mobile_potrait{
            font-size: 16px;
        }
        img{
            width: 40px;
            height: 40px;
            border-radius: 20px;
            // margin-right: 8px;
        }
        .nav_icon{
            width: 28px;
            height: 28px;
            border-radius: 0;
            transition: all 0.3s ease;
        }
        &.text_color_dark{
            color: #fff;
        }
        &.text_color_light{
            color: #000;
        }
    }
    &.active {
       .label{
        background: linear-gradient(to right,#FF00FF, #FE511B);
        background-clip: text;
        -webkit-text-fill-color: transparent;
       }
    }
    &.none {
        display: none;
    }
    .drop_down{
        display: none;
        align-items: center;
        justify-content: center;
        position: relative; 
        .drop_down_list{
            display: flex;
            flex-direction: column;
            font-weight: 600;
            position: absolute;
            top : calc(100% + 0px);
            box-shadow: 0 0 8px 1px #0000008f;
            transition: all 0.3s ease;
            z-index: 99;    
            backdrop-filter: blur(15px);
            border-radius: 12px;
            overflow: hidden;
            .drop_down_item{
                padding : 10px 20px;
                cursor: pointer;
                width: fit-content;
                box-sizing: border-box;
                width: 100%;
                &.specific-menu {
                    background: linear-gradient(to right,#FF00FF, #FE511B);
                    background-clip: text;
                    -webkit-text-fill-color: transparent;
                }
                img{
                    margin-right: 8px;
                }
                .fas{
                    margin-right: 8px;
                    font-size: 18px;
                }
            }
            &.bg_dark{
                background: #34343A;
                .drop_down_item{
                    color: #fff;
                    &:hover {
                        background-color: #444;
                    }
                }
            }
            &.bg_light{
                background: #f5f5f5;
                .drop_down_item{
                    color: #000;
                    &:hover {
                        background-color: #e1e1e1;
                    }
                }
            }
        }
        .left{
            left: 0;                    
        }
        .right{
            right: 0;
        }
    }
    .active_drop{
        display: flex;
    }
}